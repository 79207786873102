
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Sacramento&display=swap');

.containerHome{
    background-color: #F7ECDE;
    width: 100%;
    height: 100vh;
}

.controller-component{
    /* display: flex;
    justify-content: center;
    align-items: center; */
   padding-top: 80px;  
   padding-bottom: 80px;
   background-color: #F7ECDE;
}
@media (max-width: 600px) {
  .controller-component{
   padding-top: 0px;  
}
}
.image-home-one{
        padding: 10px;
        width: 450px;
        height: 450px;
        border-top-left-radius: 130px 130px;
        border-top-right-radius: 130px 130px;
        border-bottom-right-radius: 130px 130px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 13px;
}
.shape{
  padding: 10px;
  width: 470px;
  height: 470px;
  border-top-left-radius: 130px 130px;
  border-top-right-radius: 130px 130px;
  border-bottom-right-radius: 130px 130px;
  background-color: #9FCECE;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.text-1{
    font-size: 40px;
    color: #303137;
}
.text-2{
    font-size: 35px;
    color: #303137;
}
.text-3{
    font-size: 20px;
    color: #303137;
}
.olimarName{
  background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
  width: auto;
  height: 90px;
  color: #303137;
  font-size: 60px;
  /* color: #F7ECDE; */
  /* font-family: 'Alice', serif; */
}
.olimarName-reverse{
  background: linear-gradient(to right,#9FCECE 0%, #9FCECE 30%,  #809bce 70%);
  width: auto;
  height: 90px;
  color: #303137;
  font-size: 60px;
  /* color: #F7ECDE; */
  /* font-family: 'Alice', serif; */
}
strong{
  font-family: 'Sacramento', cursive;
}
.cursive{
  font-family: 'Sacramento', cursive;
}
.normal{
  font-family: 'Alice', serif;
}

.span2{
  background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
    width: auto;
    height: 40px;
    color: #303137;
     /* font-family: 'Alice', serif; */
}

.bg-text-name{
  background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
  width: auto;
  height: 40px;
  color: #303137;
   /* font-family: 'Alice', serif; */
}

/* CSS BUTTOM*/
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #9FCECE;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#95b8d1 0 -3px 0 inset;
  box-sizing: border-box;
  color: balck;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  /* font-family: 'Alice', serif; */
}

.button-30:focus {
  box-shadow: #95b8d1 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #9FCECE 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #95b8d1 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #95b8d1 0 3px 7px inset;
  transform: translateY(2px);
}

/* HERE IS THE ABOUT ME*/
section {
    /* margin-top: 100px; */
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
  }
  .conta {
    width: 90%;
    max-width: 1000px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
  }
  @media(max-width: 700px){
    .conta {
      width: 90%;
      max-width: 1000px;
      margin: 50px auto;
      display: block;
      align-items: center;
      justify-content: center; 
    }
  }
  .left {
    width: 50%;
    height: 600px;
    background: url('../img/Foto1.JPG')
      no-repeat center / cover;
    border-radius: 8px;
  }
  .right {
    width: 50%;
    min-height: 400px;
    background-color: #F7ECDE;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
    color: #303137;
    margin-left: -150px;
  }
  .right h1 {
    font-size: 40px;
    font-weight: lighter;
  }
  .right p {
    margin: 20px 0;
    font-weight: 500;
    line-height: 25px;
  }
  .right a {
    text-decoration: none;
    text-transform: uppercase;
    /* background-color: white; */
    color: black;
    padding: 5px 5px;
    display: inline-block;
    letter-spacing: 2px;
  }
  
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      width: 100%;
      margin: 0 20px;
    }
    .left {
      width: 100%;
      height: 400px;
    }
    .right {
      width: 100%;
      margin: 0;
      margin-top: -40px;
    }
    
  }


  /* /////circles/// */
  .homepage-banner-2 {
    background-color: #F7ECDE;
}
@media(max-width: 768px){
    .homepage-banner-2 {
        background-color: #F7ECDE;
    }
}
.banner-2-h1 {
    /* font-family: 'Abhaya Libre', serif;  */
    font-size: 75px
}
@media(max-width: 768px){
    .banner-2-h1 {
        /* font-family: 'Abhaya Libre', serif;  */
        font-size: 28px;
    }
}
.banner-2-circle-1 {
    justify-content: center;
}
@media (max-width: 768px) {
    .banner-2-circle-1 {
        justify-content: flex-start;
        margin-left: 15%;
        
    }
}
.padding-separation{
  padding: 40px;
}
@media(max-width:770px){
  .image-home-one{
    padding: 10px;
    width: 329px;
    height: 329px;
    border-top-left-radius: 130px 130px;
    border-top-right-radius: 130px 130px;
    border-bottom-right-radius: 130px 130px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 13px;
}
.shape{
padding: 10px;
width: 350px;
height: 350px;
border-top-left-radius: 130px 130px;
border-top-right-radius: 130px 130px;
border-bottom-right-radius: 130px 130px;
background-color: #9FCECE;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.text-1 {
  font-size: 40px;
  color: #000000;
}
}
.brand-bg-purple-2 {
    background: #D0CDFB;
}
.perfect-circle {
    width: 200px;
    height: 200px;
    border-radius:50%;
    border: none;
}
/* .carousel {
    margin: auto;
    overflow: hidden;
    overflow-x: scroll;
    overflow-x: scroll;
    width: auto;
  }

  .inner-carousel {
    display: flex;
    margin: 15px;
    margin-top: 50px;
    margin-left: 0px;
    scroll-behavior: smooth;
    animation: slidehow 12s linear infinite;
  }
  .carousel .item {
    background-color: #EAE8FB;
    min-width: 13rem;
    min-height: 14rem; 
    border-radius: 8px;
    margin-left: 10px; 
     pointer-events: none;
     margin-right: 80px;
  } 
   @keyframes slidehow{
    0%{
      transform: translateX(0%) ;
    }
    66%{
      transform: translateX(70%) ;
    }
    100%{
      transform: translateX(20%) ;
    }
  } */

  div.toast:not(.hide) {
    transition: all 0.6s ease-in-out;
  }

  .toaster-image{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }

  /* new circles idea hover flip card */

  .flip-card {
    background-color: transparent;
    width: 280px;
    height: 280px;
    perspective: 900px;
    border-radius: 50%;
  }
  .margin-top-controller-1{
    margin-top: 30px;
  }
  @media(max-width:1287px){
    .flip-card {
      background-color: transparent;
      width: 250px;
      height: 250px;
      perspective: 900px;
      border-radius: 50%;
    }
    .margin-top-controller-1{
      margin-top: 30px;
    }
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 50%;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  
  .flip-card-front {
    background-color: #9FCECE;
    color: black;
    
  }
  
  .flip-card-back {
    background-color: #809bce;
    color: white;
    transform: rotateY(180deg);
  }

  @media(max-width:1286px){
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 90%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      border-radius: 50%;
      display: flex;
      justify-content: center;
    }
  }

  @media(max-width:1171px){
    .flip-card-inner {
      position: relative;
      width: 250px;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      margin: 10px;
    }
  }

  .text-size-home{
    font-size: 18px;
  }
  .title-size{
    font-size: 30px;
  }

  @media(max-width: 1286px){
    .text-size{
      font-size: 11px;
    }
    .title-size{
      font-size: 25px;
    }
    .text-size-home{
      font-size: 14px;
    }
  }
.color-whatsApp{
  color:#25D366;
}
  .fa-whatsapp{
    font-size: 25px;
    color:#25D366;
  }