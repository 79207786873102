
.about{
    background-color: white;
    width: 100%;
    height: 80px;
}
.info-banner-padding {
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.homepage-banner-4 {
    background-color: #F7ECDE;
}
.info-banner-padding {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.info-vertical-margin {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
}
.banner-padding {
    padding-top: 60px;
    padding-bottom: 90px;
}

.orientation-flex{
display: flex;
justify-content: space-between;
align-items: center;
padding-right: 150px;
padding-left: 150px;
}
.border-decoration{
    border-right: 5px solid #18B7BE;
    padding: 20px;
    padding-right: 50px;
}
.border-decoration-left{
    border-left: 5px solid #18B7BE;
    padding: 20px; 
    padding-left: 50px;
}
.separation-border{
    padding-top: none;
    padding-bottom: 0px;
}
.carrousel-image{
    width: 50%;
    height: 500px;
    object-fit: cover;
}
.padding-controller{
padding: 0px;
}
.padding-controller-2{
    padding-bottom: 0px;
    }
@media(max-width:600px){
    .carrousel-image{
        width: 100%;
        height: 400px;
        object-fit: cover;
    } 

    .padding-controller{
        padding:20px;
        }
    .padding-controller-2{
            padding-bottom: 25px;
            }
            .banner-padding {
                padding-top: 170px;
                padding-bottom: 90px;
            }
}
.controller-lock{
    display: flex;
}
.stack-border{
    display: none !important;
}
@media(max-width:900px){
    .orientation-flex{
        display: grid;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
        padding-left: 0px;
        padding: 0px;
        }
        .border-decoration{
            border-right: none;
        }
        .border-decoration-left{
            border-left: none;
        }
        .separation-border{
            padding-top: 40px;
            padding-bottom: 40px;
            padding: 20px;
        }
        .border-responsive{
            background-color: #178CA4;
            width: 150px;
            height: 5px;
        }
        .stack-border{
            display: flex !important;
            justify-content: center;
        }
        /* .flex-controller{
            display: block;
        } */
}

.mygoals{
    background-color: #d4d7d9;
    width: 100%;
    height: auto;
    padding-bottom: 30px;
}

@media(max-width: 700px) {
    .mygoals{
        background-color: rgb(227, 227, 227);
        width: 100%;
        height: auto;
    } 
    .bg-text-1{
    background-color: #fff;
    }

    
}
.test{
    height: 500px;
    background-color: white;
}
.test22{
    height: 30px;
    background-color: white;
}

.goalsBox{
    margin-top: 50px;
    background-color: #809bce;
    width: 300px;
    height: 270px;
    border-radius: 20px;
    display: flex;
        align-items: center;    /* aligns vertically */
        justify-content: center;
  transition: transform .2s; /* Animation */
}

.goalsBox:hover {
    transform: scale(0.9);
}

.child{
    text-align: center;
}
.icon-class-image{
    width: 90px;
    height: 90px;
}
.icon-class-image:hover{
   border: red;
}
.arrows-body{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    background-color: rgb(255, 255, 255);
}
.arrrow-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 190px;
}
.separation-padding-mobile{
    padding-bottom: 0px
}
.banner-2-h2{
    font-size: 60px;
}
@media(max-width: 700px){
    .banner-2-h2{
        font-size: 30px;
    }
    .banner-2-h1{
        font-size: 50px;
    }
    .arrows-body{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        background-color: #fff;
        margin-top: -20px;
    }
    .arrrow-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 200px;
    }
    .separation-padding-mobile{
        padding-bottom: 50px
    }
}

.arrow{
    position: relative;
    width: 40px;
    height: 40px;
    border-bottom: solid 12px #178CA4;
    border-right: solid 12px rgb(159, 206, 206);
    transform: rotate(-45deg);
    animation: arrow-load 2s infinite;
}
.arrow:nth-child(1){
left: 25px;
animation-delay: -0.4s;
}
.arrow:nth-child(2){
animation-delay: -0.2s;
    }
.arrow:nth-child(3){
right: 25px;
}

@keyframes arrow-load{
    0%{
        opacity: 1;
        transform: rotate(-45deg) translate(80px, 80px);
    }
    /* 0%{
        opacity: 0;
        
    } */
    100%{
        opacity: 0;
        transform: rotate(-45deg) translate(40px, 40px);
    }
}