/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Licorice&family=Nunito:ital,wght@1,300&family=Prompt:ital,wght@1,100;1,200;1,500&family=Send+Flowers&display=swap');
h6{
  color: #070707;
}
h1{
  color: #070707;
}
.navbarcon{
  background-color: #7dd5cc;
}
.navbarcon-two{
  background-color: #F7ECDE;
}
.bg-home-two{
  background-image: url('https://img.freepik.com/free-vector/gradient-white-monochrome-background_52683-66309.jpg?w=996&t=st=1664746636~exp=1664747236~hmac=944dc6221499f7867df26fe6b890ff4a7e16af30adf571b342ef7b0c3952ec56');
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 0.9; */
}
.bg-gradient{
  position: relative;
  width: 100%;
  height: 100vh;
}

.bg-gradient canvas{
  width: 100%;
  height: 100vh;
}

.canvas-interactive-wrapper {
  position: relative;
  height: 100vh;
  text-align: center;
}

.canvas-interactive-wrapper canvas {
  position: absolute;
  /* display: block; */
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.canvas-interactive-wrapper .cta-wrapper {
  padding-top: 12%;
}

.canvas-interactive-wrapper .cta-wrapper a {
  position: relative;
  display: grid;
  padding: 3px 7px;
  border: solid 1px transparent;
  margin-right: 50px;
  z-index: 1;
  text-decoration: none;
  color: #222;
  transition: border .3s;
}

.canvas-interactive-wrapper .cta-wrapper a.active {
  border-color: #222;
}
.text-cursive{
  font-family: 'Allura', cursive;
font-size: 55px;
}
.text-gothic{
  font-size: 22px;
  font-family: 'Questrial', sans-serif;
}
.no-mobile{
  display: block;
}

@media(max-width:600px){
  .text-cursive{
    font-family: 'Allura', cursive;
  font-size: 45px;
  white-space: nowrap;
  }
  .text-gothic{
    font-size: 18px;
    font-family: 'Questrial', sans-serif;
  }
  .canvas-interactive-wrapper .cta-wrapper {
    padding-top: 50%;
  }
  .no-mobile{
    display: none;
  }
}


.button-31 {
  align-items: center;
  appearance: none;
  background-color: #809bce;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#95b8d1 0 -3px 0 inset;
  box-sizing: border-box;
  color: #303137;
  cursor: pointer;
  display: inline-flex;
  width: 200px;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-31:focus {
  box-shadow: #95b8d1 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #F7ECDE 0 -3px 0 inset;
}

.button-31:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #95b8d1 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-31:active {
  box-shadow: #95b8d1 0 3px 7px inset;
  transform: translateY(2px);
}
.parallax-bg{
  width: 100%;
  height: 700px;
}
.no-pc{
  display: none;
}
@media(max-width: 650px){
  .parallax-bg{
    width: 100%;
    height: 300px;
  }
  .no-pc{
    display: block;
  }
}
.title-parralax-div{
  background-color: #F7ECDE;
  opacity: 0.9;
  width: 500px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
}
.title-parralax-div-2{
  background-color: #F7ECDE;
  opacity: 0.9;
  width: 500px;
  height: 170px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-size{
  font-size: 40px !important;
}
text-size-co{
  
  font-size: 40px !important;
}
@media(max-width: 650px){
  .title-parralax-div{
    background-color: #F7ECDE;
    opacity: 0.9;
    width: 300px;
    height: auto;
    padding: 20px;
    border-radius: 8px;
  }
  .title-parralax-div-2{
    background-color: #F7ECDE;
    opacity: 0.9;
    width: 350px;
    height: 135px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-size{
    font-size: 30px !important;
  }
  .text-size-co{
  
    font-size: 25px !important;
  }
}
@media(max-width:400px ){
  .title-parralax-div-2{
    background-color: #F7ECDE;
    opacity: 0.9;
    width: 300px;
    height: 185px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.parallax-quote{
  width: 100%;
  height: 300px;
}
.quote-parralax-div{
  color: black;
}
.quote-parralax-two{
  color: #F7ECDE;
}
.quote-text{
  font-size: 40px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: 'Nunito', sans-serif;
}
.quote-text-new{
  font-size: 40px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: 'Nunito', sans-serif;
}
.quote-text-larger{
  font-size: 40px;
  padding-right: 10px;
  padding-left: 35px;
  font-family: 'Nunito', sans-serif;
  color: #F7ECDE !important;
}
.fa-quote-right{
  font-size: 20px;
  padding-right: 10px;
}
.fa-quote-left{
  font-size: 20px;
}
.logo{
  width: 50px;
  height:50px;
  border-radius: 50%;
}
.cursive{
  font-family: 'Sacramento', cursive;
}
.normal{
  font-family: 'Alice', serif;
}

.footer-sec{
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9FCECE;
}
.quote-parralax-div-team{
  color: #F7ECDE;
}
.text-br{
  color: #F7ECDE !important;
}

.controller-navbar{
  justify-content: space-between;
}

.controller-links{
  margin-left: 440px;
}
@media(max-width: 800px){
  .controller-links{
    margin-left: 0px;
  }
  .quote-text{
    font-size: 40px;
    padding-right: 10px;
    padding-left: 35px;
    font-family: 'Nunito', sans-serif;
  }
  .quote-text-larger{
    font-size: 30px;
    padding-right: 10px;
    padding-left: 0px;
    font-family: 'Nunito', sans-serif;
  }
  .quote-text-new{
    font-size: 30px;
    padding-right: 10px;
    padding-left: 10px;
    font-family: 'Nunito', sans-serif;
  }

}
.navbar-mobile{
 display: none;
}
.p-controller{
  padding-top: 10px;
}
@media(max-width: 650px){
  .fa-quote-right{
    font-size: 15px;
    padding: 2px;
  }
  .fa-quote-left{
    font-size: 15px;
    padding: 2px;
  }

  .navbar-mobile{
    background-color: #178CA4;
    border-radius: 12px;
    display: block;
    margin: 15px;
    text-align: center;
  }
  .p-controller{
    padding-top: 10px;
  }
}
.color1{
width: 300px;
height: 300px;
background-color: #809bce;
}
.color2{
  width: 300px;
height: 300px;
background-color: rgb(159, 206, 206);;
}
.color3{
  width: 300px;
height: 300px;
background-color: #F7ECDE;
}
.color4{
  width: 300px;
height: 300px;
background-color: #d4d7d9;
}
/* second pallets colors */
.color5{
  width: 300px;
  height: 300px;
  background-color: #C0A9BD;
  }
  .color6{
    width: 300px;
  height: 300px;
  background-color: #94A7AE;
  }
  .color7{
    width: 300px;
  height: 300px;
  background-color: #F4F2F3;
  }
  .color8{
    width: 300px;
  height: 300px;
  background-color: #64766A;
  }


/* 3 pallets colors */
.color9{
  width: 300px;
  height: 300px;
  background-color: #FBE0C3;
  }
  .color10{
    width: 300px;
  height: 300px;
  background-color: #FFBB98;
  }
  .color11{
    width: 300px;
  height: 300px;
  background-color: #7D8E95;
  }
  .color12{
    width: 300px;
  height: 300px;
  background-color: #344648;
  }

/* 4 pallets colors */
.color13{
  width: 300px;
  height: 300px;
  background-color: #E8ECEB;
  }
  .color14{
    width: 300px;
  height: 300px;
  background-color: #E09E50;
  }
  .color15{
    width: 300px;
  height: 300px;
  background-color: #8CBDB9;
  }
  .color16{
    width: 300px;
  height: 300px;
  background-color: #2D3E4E;
  }

  /* 5 pallets colors */
.color17{
  width: 300px;
  height: 300px;
  background-color: #eac4d5;
  }
  .color18{
    width: 300px;
  height: 300px;
  background-color: #18B7BE;
  }
  .color19{
    width: 300px;
  height: 300px;
  background-color: #178CA4;
  }
  .color20{
    width: 300px;
  height: 300px;
  background-color: #072A40;
  }

.promp{
  font-family: 'Prompt', sans-serif;
  color: #072A40;
}

.flower{
  font-family: 'Send Flowers', cursive;
  color: #072A40;
}

.nunito{
  font-family: 'Nunito', sans-serif;
  color: #072A40;
}

.Licorice{
  font-family: 'Licorice', cursive;
  color: #072A40;
}

.Cedarville{
  font-family: 'Cedarville Cursive', cursive;
  color: #072A40;
}
.oswald{
  font-family: 'Oswald', sans-serif;
  color: #072A40;
}
.text-autor{
  color: #F7ECDE;
  display: flex;
  justify-content: end;
  font-size: 40px;
}

.text-autor-black{
  color: black;
  display: flex;
  justify-content: end;
  font-size: 40px;
}
@media(max-width: 700px){
  .text-autor{
    color: #F7ECDE;
    display: flex;
    justify-content: end;
    font-size: 25px;
    margin-right: 50px;
  }
  
  .text-autor-black{
    color: black;
    display: flex;
    justify-content: end;
    font-size: 25px;
    margin-right: 50px;
  }
}
.icon{
  color: white;
  font-size: 30px;
}

.icon:hover{
  color: #809bce;
}
.margin-align{
  margin-top: 12px;
}
.test-image{
  padding-top: 30px;
}