.bg-one-services{
  background-color: #F7ECDE;
  width: 100%;
  height:700px;
}
@media(max-width:700px){
  .bg-one-services{
    background-color: #F7ECDE;
    width: 100%;
    height:auto;
  }
}

.containerServices{
    background-color: #F7ECDE;
    width: 100%;
    height: 1099px;
}
@media(min-width:1290px){
  .containerServices{
    background-color: #F7ECDE;
    width: 100%;
    height:  1450px;
}
}
@media(min-width:1135px){
  .containerServices{
    background-color: #F7ECDE;
    width: 100%;
    height:  auto;
}
}
@media(max-width:1000px){
  .containerServices{
    background-color: #F7ECDE;
    width: 100%;
    height:  1558px;
}
}
@media(max-width:900px){
  .containerServices{
    background-color: #F7ECDE;
    width: 100%;
    height:  1658px;
}
}
@media(max-width:771px){
  .containerServices{
    background-color: #F7ECDE;
    width: 100%;
    height: auto;
}
}

.selec {
    height: auto;
    width: 100%;
    display: grid;
    place-items: center;
    padding: 50px;
    background-color: #F7ECDE;
  }
  @media (max-width: 770px) {
    .selec {
        height: 1673;
        width: 100%;
        display: grid;
        place-items: center;
        padding: 50px;
        background-color: #F7ECDE;
      }
}
  @media (max-width: 600px) {
    .selec {
        height: auto;
        width: 100%;
        display: grid;
        place-items: center;
        padding: 50px;
        background-color: #F7ECDE;
      }
}
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .column {
    width: 100%;
    padding: 0 1em 1em 1em;
    text-align: center;
  }
  .card-services {
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    background: linear-gradient(#ffffff 35%, #809bce  65%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
  }
  p {
    color: #070707;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
  }
  .icon-wrapper {
    background-color: #809bce;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2em;
    width: 2em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
    
  }
  .card-services:hover {
    background-position: 0 100%;
  }
  .card-services:hover .icon-wrapper {
    background-color: #fff;
    color: #976BAE;
  }
  .card-services:hover h3 {
    color: #ffffff;
  }
  .card-services:hover p {
    color: #f0f0f0;
  }

  /* //new color  */
  
  @media screen and (min-width: 768px) {
    section {
      padding: 0 2em;
    }
    .column {
      flex: 0 50%;
      max-width: 50%;
    }
  }
  @media screen and (min-width: 992px) {
    section {
      padding: 1em 3em;
    }
    .column {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  .image-services{
    width: 100%;
    height: 700px;
  }
  .font-services-title{
    font-size: 55px;
  }
  .review-text{
    font-size: 55px;
  }
  @media(max-width:650px){
    .font-services-title{
      font-size: 45px;
    }
    .review-text{
      font-size: 50px;
    }
    .image-services{
      width: 100%;
      height: 550px;
    }
  }
  .bg-text{
    background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
    width: auto;
    height: 80px;
    border-radius: 8px;
  }
  .services-image-two{
    width: 100%;
    height: 380px;
    border-radius: 10px;
    object-fit: cover;
  }
  .services-image-three{
    width: 100%;
    height: 610px;
    border-radius: 10px;
    object-fit: cover;
  }
  @media(max-width:1286px){
    .services-image-three{
      width: 100%;
      height: 561px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  @media(max-width:829px){
    .services-image-three{
      width: 100%;
      height: 650px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  @media(max-width:700px){
    .services-image-two{
      width: 100%;
      height: 330px;
      border-radius: 40px;
      margin-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      object-fit: cover;
    }
    .services-image-three{
      width: 100%;
      height: 350px;
      margin-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 40px;
      object-fit: cover;
    }
    
}
 
  .banner-two-service{
    background-color: #fff;
    width: 100%;
    height: auto;
    margin-top: 75px;
    margin-bottom: 75px;
  }
  @media(max-width:700px){
    .banner-two-service {
      background-color: #fff;
      width: 100%;
      height: auto;
      margin-top: 35px;
      margin-bottom: 75px;
  }
}

  .banner-three-service{
    background-color: #9FCECE;
    width: 100%;
    height: auto;
    padding-top: 75px;
    padding-bottom: 75px;
  }
  @media(max-width:700px){
    .banner-three-service{
      background-color: #9FCECE;
      width: 100%;
      height: auto;
      padding-top: 50px;
      padding-bottom: 75px;
    }
}

 
  .text-controller{
    padding-left: 90px;
    width: 70%;
  }
  .text-controller-2{
    padding-left: 10px;
    width: 65%;
    text-align: center;
  }
  .question-mark{
    font-size: 75px;
    color: black !important;
  }
  .padding-mark{
    padding-right: 0px;
  }
  @media(max-width:700px){
    .question-mark{
      font-size: 55px;
      padding-left: 20px;
      padding-right: 10px;
    }
    .padding-mark{
      padding-left: 0px;
    }
  }
  .porqueYo{
    font-size: 75px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .terapyList{
    font-size: 75px;
    /* padding-left: 100px; */
    /* padding-right: 10px; */
  }

  .list-text{
    font-size: 18px;
    padding: 3px;
  }

  @media(max-width:1286px){
    .list-text{
      font-size: 18px;
      padding: 0px;
    }
  }

  @media(max-width: 700px){
    .porqueYo{
      font-size: 50px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
    .terapyList{
      font-size: 50px;
      padding-left: 20px; 
      padding-right: 20px;
    }
    .list-text{
      padding-left: 30px;
      padding-right: 30px;
        }
  }


  .center-list{
    display: inline-block;
    text-align: center;
}


.reviews{
width: 100%;
height: 600px;
background-color: #fff;
 /* eac4d5 */
}
.card{
  background-color: #9FCECE !important;
}

@media(max-width:700px){
  .reviews{
    width: 100%;
    height: auto;
    background-color: #fff;
    padding-bottom: 50px;
    }
}
.fa-star{
  color: #ffff3b;

}
.small{
  font-size: 08px;
}
.card-reviews{
  width: 390px;
}
.padding-controller-text{
  padding: 0px;
  padding-left: 32px;
    padding-right: 32px;
}
.separation-padding{
  padding-left: 20px;
}
@media(max-width:700px){
  .card-reviews{
    width: 350px;
    margin-top: 40px;
  }
  .padding-controller-text{
    padding-left:35px;
    padding-right: 35px;
  }
  .separation-padding{
    padding-left: 40px;
  }
}

.fa-check-circle{
  color: #18B7BE;
}
.bg-modal{
  background-color: #18B7BE;
}
.text-size-controller{
  font-size: 15px;
}

.whatsAppflatin{
  position:fixed;
	bottom:80px;
	right:40px;
	width:60px;
	height:60px;
	background-color:#25D366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;

  z-index: 9;
}
.color-whatsApp-floating{
  color: white !important;
  font-size: 40px;
}

.whatsAppflatin-icon {
  margin-top:17px;
	font-size:25px;
  }
