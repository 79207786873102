/* .containerContact{
    background-color: lightcoral;
    width: 100%;
    height: 100vh;
} */

/* *:not(head, script, svg, svg *),
*:not(head, script, svg, svg *)::before,
*:not(head, script, svg, svg *)::after {
  all: initial;
  box-sizing: border-box;
  font-family: sans-serif;
} */

.containerContact{
    background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
    font-size: 12px;
    height: 800px;
  }
  
  @media(max-width: 1000px){
    .containerContact{
      background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
      font-size: 12px;
      height: 1150px;
    }
  }
  body, button, input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 1.4px;
  }
  
  .quote-first-banner{
    background-color: #D0CDFB;
    color: rgb(6, 6, 6);
    height: 200px;
    padding: 50px;
  }
  .background {
    display: flex;
    min-height: 100vh;
  }
  
  .container {
    flex: 0 1 700px;
    margin: auto;
    padding: 10px;
  }
  
  .screen {
    position: relative;
    background: #F7ECDE;
    border-radius: 15px;
  }
  
  .screen:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
    z-index: -1;
  }
  
  .screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #976BAE;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .screen-header-left2 {
    margin-right: auto;
  }
  
  .screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: white;
  }
  
  .screen-header-button.close {
    background: #ed1c6f;
  }
  
  .screen-header-button.maximize {
    background: #e8e925;
  }
  
  .screen-header-button.minimize {
    background: #74c54f;
  }
  
  .screen-header-right {
    display: flex;
  }
  
  .screen-header-ellipsis {
    width: 3px;
    height: 3px;
    margin-left: 2px;
    border-radius: 8px;
    background: #999;
  }
  
  .screen-body {
    display: flex;
  }
  
  .screen-body-item {
    flex: 1;
    padding: 50px;
  }
  
  .screen-body-item.left2 {
    display: flex;
    flex-direction: column;
  }
  
  .app-title {
    display: flex;
    flex-direction: column;
    position: relative;
    color: #976BAE;
    font-size: 26px;
  }
  
  .app-title:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 25px;
    height: 4px;
    background: #976BAE;
  }
  
  .app-contact {
    margin-top: auto;
    font-size: 8px;
    color: #888;
  }
  
  .app-form-group {
    margin-bottom: 15px;
  }
  
  .app-form-group.message {
    margin-top: 40px;
  }
  
  .app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
  }
  
  .app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: #ddd;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    transition: border-color .2s;
  }
  
  .app-form-control::placeholder {
    color: #666;
  }
  
  .app-form-control:focus {
    border-bottom-color: #ddd;
  }
  
  .app-form-button {
    background: none;
    border: none;
    color: #976BAE;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }
  
  .app-form-button:hover {
    color: #976BAE;
  }
  
  .credits {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #ffa4bd;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    font-weight: normal;
  }
  
  .credits-link {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }
  
  .dribbble {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  
  @media screen and (max-width: 520px) {
    .screen-body {
      flex-direction: column;
    }
  
    .screen-body-item.left2 {
      margin-bottom: 30px;
    }
  
    .app-title {
      flex-direction: row;
    }
  
    .app-title span {
      margin-right: 12px;
    }
  
    .app-title:after {
      display: none;
    }
  }
  
  @media screen and (max-width: 700px) {
    .screen-body {
      padding: 40px;
    }
  
    .screen-body-item {
      padding: 0;
    }
    .containerContact{
      background: linear-gradient(to right,#809bce 0%, #9FCECE 30%,  #9FCECE 70%);
      font-size: 12px;
      height: 1450px;
    }
  }
  
  .crop{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 1000px;
    width: 800px;
    padding: 5vmin;
    border-radius: 2vmin;
  }

  .crop::before {
    position: absolute;
    /* width: calc(100% - 2px);
    height: calc(100% - 2px); */
    background:plum;
    filter: drop-shadow(15vmin 15vmin 22vmin navy);
    transform-box: fill-box;
    -webkit-transform-box: fill-box;
    transform-origin: center;
    -webkit-transform-origin: center;
    height: 800px;
    width: 700px;
    content: "";
    z-index: -10;
    animation: shadowRotate 5s linear infinite;
  }
  
  @keyframes shadowRotate {
    to {
      transform: rotateZ(360deg);
    }
  }



  /* test//////////// */

  .test1{
    /* width: 400px;
    height: 400px; */
    z-index: 1;
    position: absolute;
    margin-left: 100px;
    margin-top: 100px;
    padding-left: 300px;
  }
  .test2{
    background-color: #F7ECDE;
    width: 600px;
    height: 660px;
    /* z-index: 2; */
    position: absolute;
    border-radius: 8px;
    
  }
  .calendly-inline-widget{
    height: 1000px;
    width: 650px;
    z-index: 2;
    position: absolute;
    margin-left: -80px;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  @media(max-width:1000px){
    .test2{
      background-color: #F7ECDE;
      width: 340px;
      height: 660px;
      /* z-index: 2; */
      position: absolute;
      border-radius: 8px;
      margin-left: 370px;
      
    }
    .test1{
      /* width: 400px;
      height: 400px; */
      z-index: 1;
      position: absolute;
      margin-left: -100px;
      margin-top: 700px;
      padding-left: 271px;
    }

    .calendly-inline-widget{
      height: 600px;
      width: 340px;
      z-index: 2;
      position: absolute;
      margin-left: -70px;
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }
  .test3{
    background-color: #D0CDFB;
    width: 500px;
    height: 400px;
  }

  .input-contactme{
    width: 330px;
    height: 40px;
    border-bottom: 5px solid #eac4d5;
    border-top: hidden;
    border-right: hidden;
    border-left: hidden;
    background-color: #F7ECDE;
  }
  .textarea-contactme{
    
      width: 330px;
      height: 100px;
      border-bottom: 5px solid #eac4d5;
      border-top: hidden;
      border-right: hidden;
      border-left: hidden;
      background-color: #F7ECDE;
    
  }
  .controller-div{
    display: flex;
    justify-content: center;
    margin-right: 260px;
  }

  @media(max-width: 1287px){
    .controller-div{
      display: flex;
      justify-content: center;
      margin-right: 320px;
    }
  }


  .div1{
    width: 660px;
    height: 510px;
    z-index: 1;
    position: absolute;
    background-color: transparent;
    border-radius: 8px;
    /* padding-top: 100px; */
  }
  /* .image-contactme{
    width: 400px;
    height: 400px;
  } */
  .div2{
    background-color: #eac4d5;
    width: 630px;
    height: 300px;
    z-index: 2;
    position: absolute;
    border-radius: 8px;
    margin-left: 0px;
    margin-top: 150px;
    
  }
  .second-div-controller{
   margin-left: -50px;
   margin-top: 100px;
   margin-bottom: 100px;
   display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 260px;
    margin-top: 200px;

  }
  @media(max-width: 1287px){
    .second-div-controller{
      margin-right: 300px;
      margin-top: 200px;
      margin-bottom: 100px;
     
     }
  }

  .bg-div-two{
    background-color: #fff;
    height: 600px;
  }

  @media(max-width: 1000px){
    .div1{
      width: 460px;
      height: 310px;
      z-index: 1;
      position: absolute;
      background-color: transparent;
      /* padding-top: 100px; */
    }
 
    .div2{
      background-color: #eac4d5;
      width: 430px;
      height: 300px;
      z-index: 2;
      position: absolute;
      border-radius: 8px;
      margin-left: 0px;
      margin-top: 100px;
      
    }
    .second-div-controller{
     margin-left: 0px;
     margin-top: 50px;
     margin-bottom: 0px;
     display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 260px;
      margin-top: 200px;
  
    }

    .bg-div-two{
      background-color: #fff;
      height: 500px;
    }
  }

  @media(max-width: 1000px){
    .input-contactme{
      width: 290px;
      height: 40px;
      border-bottom: 5px solid #eac4d5;
      border-top: hidden;
      border-right: hidden;
      border-left: hidden;
      background-color: #F7ECDE;
    }
    .textarea-contactme {
      width: 290px;
      height: 100px;
      border-bottom: 5px solid #eac4d5;
      border-top: hidden;
      border-right: hidden;
      border-left: hidden;
      background-color: #F7ECDE;
  }
  }
  @media(max-width: 700px){
    .input-contactme{
      width: 250px;
      height: 40px;
      border-bottom: 5px solid #eac4d5;
      border-top: hidden;
      border-right: hidden;
      border-left: hidden;
      background-color: #F7ECDE;
    }
    .textarea-contactme {
      width: 250px;
      height: 100px;
      border-bottom: 5px solid #eac4d5;
      border-top: hidden;
      border-right: hidden;
      border-left: hidden;
      background-color: #F7ECDE;
  }

  .div1{
    width: 340px;
    height: 510px;
    z-index: 1;
    position: absolute;
    background-color: transparent;
    /* padding-top: 100px; */
    margin-top: 300px;
    margin-left: -40px;
    object-fit: cover;
  }

  .div2{
    background-color: #eac4d5;
    width: 340px;
    height: 380px;
    z-index: 2;
    position: absolute;
    border-radius: 8px;
    margin-top: 43px;
    object-fit: cover;
    margin-left: 263px;
    
  }
  .bg-div-two{
    background-color: #fff;
    height: 860px;
  }
  }
.text-color{
  color: rgb(31, 31, 31);
}
 